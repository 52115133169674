import { faBarcodeRead, faCameraRotate } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BarcodeFormat, DecodeHintType, MultiFormatReader, HTMLCanvasElementLuminanceSource, BinaryBitmap, HybridBinarizer } from "@zxing/library"; // eslint-disable-line unused-imports/no-unused-imports
import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { Button, FormGroup, FormText, Input, Label } from "reactstrap";
import { BarcodeFilter } from "../../actions/Tenants/config/constantsTyped";
import { toast } from "../../utils/toast";
import { handlePatternValidation } from "./MetaForm";

// Interface for QR code scanner properties
export interface QRCodeScanProps {
    onScan: (decodedText: string) => void;
    barcode_filter?: BarcodeFilter | ((decodedText: string) => string);
}
// // Bilateral Filter Function
// function applyBilateralFilter(imageData) { // eslint-disable-line
//     const { width, height, data } = imageData;
//     const result = new ImageData(width, height);
//     const resultData = result.data;
//     const sigmaSpace = 1; // Reduced spatial influence
//     const sigmaColor = 5; // Increased color sensitivity
//     const kernelSize = 3; // Smaller kernel size1
//     const halfKernel = Math.floor(kernelSize / 2);
//     const sigmaSpace2 = 2 * sigmaSpace * sigmaSpace;
//     const sigmaColor2 = 2 * sigmaColor * sigmaColor;

//     for (let y = 0; y < height; y++) {
//         for (let x = 0; x < width; x++) {
//             let sum = [0, 0, 0];
//             let weightSum = 0;

//             const baseIndex = (y * width + x) * 4;
//             const baseColor = [data[baseIndex], data[baseIndex + 1], data[baseIndex + 2]];

//             for (let ky = -halfKernel; ky <= halfKernel; ky++) {
//                 for (let kx = -halfKernel; kx <= halfKernel; kx++) {
//                     const ny = y + ky;
//                     const nx = x + kx;
//                     if (nx >= 0 && nx < width && ny >= 0 && ny < height) {
//                         const index = (ny * width + nx) * 4;
//                         const neighborColor = [data[index], data[index + 1], data[index + 2]];

//                         const spatialDistSq = kx * kx + ky * ky;
//                         const colorDistSq = (neighborColor[0] - baseColor[0]) ** 2
//                             + (neighborColor[1] - baseColor[1]) ** 2
//                             + (neighborColor[2] - baseColor[2]) ** 2;

//                         const spatialWeight = Math.exp(-spatialDistSq / sigmaSpace2);
//                         const colorWeight = Math.exp(-colorDistSq / sigmaColor2);

//                         const weight = spatialWeight * colorWeight;

//                         sum[0] += neighborColor[0] * weight;
//                         sum[1] += neighborColor[1] * weight;
//                         sum[2] += neighborColor[2] * weight;
//                         weightSum += weight;
//                     }
//                 }
//             }

//             resultData[baseIndex] = sum[0] / weightSum;
//             resultData[baseIndex + 1] = sum[1] / weightSum;
//             resultData[baseIndex + 2] = sum[2] / weightSum;
//             resultData[baseIndex + 3] = data[baseIndex + 3]; // Preserve alpha channel
//         }
//     }

//     return result;
// }

export const applyConvolution = (data, width, height, kernel) => {
    const output = new Uint8ClampedArray(data.length);
    const side = Math.round(Math.sqrt(kernel.length));
    const halfSide = Math.floor(side / 2);

    for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
            let r = 0;
            let g = 0;
            let b = 0;

            for (let ky = 0; ky < side; ky++) {
                for (let kx = 0; kx < side; kx++) {
                    const px = x + kx - halfSide;
                    const py = y + ky - halfSide;

                    if (px >= 0 && px < width && py >= 0 && py < height) {
                        const pos = (py * width + px) * 4;
                        const weight = kernel[ky * side + kx];

                        r += data[pos] * weight;
                        g += data[pos + 1] * weight;
                        b += data[pos + 2] * weight;
                    }
                }
            }

            const i = (y * width + x) * 4;
            output[i] = Math.min(Math.max(r, 0), 255);
            output[i + 1] = Math.min(Math.max(g, 0), 255);
            output[i + 2] = Math.min(Math.max(b, 0), 255);
            output[i + 3] = data[i + 3]; // preserve alpha channel
        }
    }

    return output;
};


export const equalizeHistogram = (imageData, sensitivity = 5) => {
    // Ensure sensitivity is within the valid range
    sensitivity = Math.max(1, Math.min(9, sensitivity));

    const data = imageData.data; // eslint-disable-line prefer-destructuring
    const histogram = new Array(256).fill(0);
    let mean = 0;
    let variance = 0;

    // Step 1: Calculate histogram and mean grayscale value
    for (let i = 0; i < data.length; i += 4) {
        const gray = data[i]; // Assuming image is already grayscale
        histogram[gray]++;
        mean += gray;
    }

    const totalPixels = imageData.width * imageData.height;
    mean /= totalPixels;

    // Step 2: Calculate variance (standard deviation squared)
    for (let i = 0; i < 256; i++) {
        variance += ((i - mean) ** 2) * histogram[i];
    }
    variance /= totalPixels;
    const stdDev = Math.sqrt(variance);

    // Step 3: Decide whether to perform equalization
    let performEqualization = false;

    if (sensitivity === 9) {
        // Always perform equalization
        performEqualization = true;
    } else if (sensitivity === 1) {
        // Skip equalization
        performEqualization = false;
    } else {
        // Calculate threshold based on sensitivity
        const maxStdDev = 74; // Approximate maximum standard deviation for an 8-bit grayscale image
        const threshold = ((10 - sensitivity) / 9) * maxStdDev;

        // If the standard deviation is less than the threshold, perform equalization
        if (stdDev < threshold) {
            performEqualization = true;
        }
    }

    if (performEqualization) {
        // Step 4: Calculate cumulative histogram
        const cumulativeHistogram = new Array(256).fill(0);
        cumulativeHistogram[0] = histogram[0]; // eslint-disable-line prefer-destructuring
        for (let i = 1; i < 256; i++) {
            cumulativeHistogram[i] = cumulativeHistogram[i - 1] + histogram[i];
        }

        // Step 5: Normalize cumulative histogram
        const scaleFactor = 255 / totalPixels;
        const lookupTable = cumulativeHistogram.map((count) => Math.round(count * scaleFactor));

        // Step 6: Apply equalization to each pixel
        for (let i = 0; i < data.length; i += 4) {
            const gray = data[i];
            const equalizedValue = lookupTable[gray];

            // Assign the equalized grayscale value to each color channel
            data[i] = equalizedValue;
            data[i + 1] = equalizedValue;
            data[i + 2] = equalizedValue;
            // Alpha channel remains unchanged
        }
    }

    return imageData;
};

// Function to apply Gaussian blur using applyConvolution
export const applyGaussianBlur = (imageData, width, height) => {
    // Define a simple 3x3 Gaussian kernel
    const gaussianKernel = [
        1 / 16, 2 / 16, 1 / 16,
        2 / 16, 4 / 16, 2 / 16,
        1 / 16, 2 / 16, 1 / 16
    ];
    // Apply convolution with the Gaussian kernel
    const blurredData = applyConvolution(imageData.data, width, height, gaussianKernel);
    // Update the ImageData object with the blurred data
    imageData.data.set(blurredData);
    return imageData;
};

// QR code scanner implementation using react-webcam and ZXing with a flexible scan area
export const QRCodeScan: React.FC<QRCodeScanProps> = ({ onScan, barcode_filter }) => {
    const webcamRef = useRef<Webcam>(null);
    const hints = useRef(new Map());
    const reader = useRef(new MultiFormatReader());
    const timerRef = useRef<any | null>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [facingMode, setFacingMode] = useState("environment"); // Default to rear camera

    const { filter, formatsToSupport } = typeof barcode_filter === "object" ? barcode_filter : { filter: barcode_filter, formatsToSupport: undefined };
    console.log(formatsToSupport); // eslint-disable-line no-console

    // Set barcode formats and hints
    useEffect(() => {
        const formats = [BarcodeFormat.AZTEC,
            BarcodeFormat.CODABAR,
            BarcodeFormat.CODE_39,
            BarcodeFormat.CODE_93,
            BarcodeFormat.CODE_128,
            BarcodeFormat.DATA_MATRIX,
            // BarcodeFormat.EAN_8,
            // BarcodeFormat.EAN_13,
            BarcodeFormat.ITF,
            BarcodeFormat.MAXICODE,
            BarcodeFormat.PDF_417,
            BarcodeFormat.QR_CODE,
            BarcodeFormat.RSS_14,
            BarcodeFormat.RSS_EXPANDED,
            // BarcodeFormat.UPC_A,
            // BarcodeFormat.UPC_E,
            // BarcodeFormat.UPC_EAN_EXTENSION
        ];
        hints.current.set(DecodeHintType.POSSIBLE_FORMATS, formats);
        hints.current.set(DecodeHintType.CHARACTER_SET, "UTF-8");
        hints.current.set(DecodeHintType.TRY_HARDER, true);
        reader.current.setHints(hints.current);
    }, []);

    const handleScan = useCallback(
        (decodedText: string) => {
            const filteredValue = typeof filter === "function" ? filter(decodedText) : decodedText;
            if (filteredValue) {
                onScan(filteredValue);
            }
        },
        [onScan, filter]
    );

    const captureAndScan = useCallback(async () => {
        if (!webcamRef.current) return;

        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc && canvasRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");

            const img = new Image();
            img.src = imageSrc;
            img.onload = () => {
                const videoWidth = img.width;
                const videoHeight = img.height;
                // handleScan(videoWidth + "---" + videoHeight);

                // Define a flexible scanning area
                // Determine the orientation and adjust the scan area accordingly
                let scanAreaWidth;
                let scanAreaHeight;

                if (videoWidth > videoHeight) {
                    // Landscape orientation
                    scanAreaWidth = videoWidth * 0.6;
                    scanAreaHeight = videoHeight * 0.4;
                } else {
                    // Portrait orientation
                    scanAreaWidth = videoWidth * 0.8;
                    scanAreaHeight = videoHeight * 0.3;
                }
                const scanAreaLeft = (videoWidth - scanAreaWidth) / 2;
                const scanAreaTop = (videoHeight - scanAreaHeight) / 2;
                // console.log(scanAreaWidth + "---" + scanAreaHeight); // eslint-disable-line no-console

                // Set canvas dimensions and draw the scanning area
                canvas.width = scanAreaWidth;
                canvas.height = scanAreaHeight;
                ctx?.drawImage(
                    img,
                    scanAreaLeft,
                    scanAreaTop,
                    scanAreaWidth,
                    scanAreaHeight,
                    0,
                    0,
                    scanAreaWidth,
                    scanAreaHeight
                );

                // Use HTMLCanvasElementLuminanceSource for scanning
                const luminanceSource = new HTMLCanvasElementLuminanceSource(canvas);
                const binaryBitmap = new BinaryBitmap(new HybridBinarizer(luminanceSource));

                try {
                    const result = reader.current.decode(binaryBitmap);
                    const resultText = result.getText();
                    const beepAudio = new Audio("/sound/beep.mp3");
                    beepAudio.play();
                    handleScan(resultText);
                } catch (e) {
                    if (process.env.NODE_ENV === "development" && e instanceof Error) {
                        if (e.name !== "NotFoundException") {
                            console.error("An error occurred during decoding:", e); // eslint-disable-line no-console
                        }
                    }
                }
                // Debug mode: Apply additional filters and reattempt decoding
                const isDebugMode = true; // Set to true to enable debugging
                if (isDebugMode) {
                    const fullImageData = ctx?.getImageData(0, 0, canvas.width, canvas.height);
                    if (fullImageData) {
                        const equalizedImageData = equalizeHistogram(fullImageData);
                        ctx?.clearRect(0, 0, canvas.width, canvas.height);
                        ctx?.putImageData(equalizedImageData, 0, 0);

                        const luminanceSourceDebug = new HTMLCanvasElementLuminanceSource(canvas);
                        const binaryBitmapDebug = new BinaryBitmap(new HybridBinarizer(luminanceSourceDebug));

                        try {
                            const resultDebug = reader.current.decode(binaryBitmapDebug);
                            const resultTextDebug = resultDebug.getText();
                            console.log("Decoded [Debug Mode]:", resultTextDebug); // eslint-disable-line no-console
                            handleScan(resultTextDebug);
                        } catch (e) {
                            if (process.env.NODE_ENV === "development" && e instanceof Error) {
                                if (e.name !== "NotFoundException") {
                                    console.error("An error occurred during decoding in debug mode:", e); // eslint-disable-line no-console
                                }
                            }
                        }
                    }
                }
            };
        }
    }, [handleScan, webcamRef, canvasRef]);

    // Automatically start capturing if scanning is enabled
    useEffect(() => {
        if (timerRef.current) clearInterval(timerRef.current);
        timerRef.current = setInterval(async () => {
            await captureAndScan();
        }, 300); // Adjust interval as needed
        return () => clearInterval(timerRef.current);
    }, [captureAndScan]);

    const onCameraError = (error) => {
        toast.error(`Failed to start camera: (${error.name}:${error.message}). Please stop any application that is using your camera.`, {
            toastId: "create-location",
            autoClose: 3500,
        });
    };

    const toggleFacingMode = useCallback(() => {
        setFacingMode((prevMode) => (prevMode === "environment" ? "user" : "environment"));
    }, []);

    return (
        <div style={{ width: "100%", height: "400px", position: "relative" }}>
            <div style={{ position: "relative", width: "100%", height: "100%" }}>
                <Webcam
                    ref={webcamRef}
                    screenshotFormat="image/png"
                    forceScreenshotSourceSize={true}
                    videoConstraints={{
                        facingMode,
                        width: 960,
                        height: 720,
                    }}
                    width="100%"
                    height="100%"
                    imageSmoothing={false}
                    onUserMediaError={onCameraError}
                    style={{ display: "block" }}
                    screenshotQuality={1}
                />

                {/* Toggle Camera Button */}
                <button
                    onClick={toggleFacingMode}
                    style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        backgroundColor: "white",
                        borderRadius: "50%",
                        border: "none",
                        padding: "10px",
                        cursor: "pointer",
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                        zIndex: 1,
                    }}
                >
                    <FontAwesomeIcon icon={faCameraRotate} />
                </button>

                {/* Gray Overlay Around Clear Center for QR Scanning Box */}
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        pointerEvents: "none",
                        zIndex: 0,
                        clipPath: "polygon(0% 0%, 0% 100%, 20% 100%, 20% 35%, 80% 35%, 80% 65%, 20% 65%, 20% 100%, 100% 100%, 100% 0%)",
                    }}
                ></div>

                {/* QR Scanning Box Outline */}
                <div
                    style={{
                        position: "absolute",
                        top: "35%",
                        left: "20%",
                        width: "60%",
                        height: "30%",
                        border: "2px solid #00ff00",
                        boxSizing: "border-box",
                        zIndex: 1,
                    }}
                ></div>
            </div>

            <canvas
                ref={canvasRef}
                style={{
                    border: "1px solid black",
                    marginTop: "10px",
                    display: "none",
                }}
            ></canvas>
        </div>
    );
};

// Text QR code scanner properties
export interface TextQRcodeScannerProps {
    item: any;
    object: any;
    setValue: (name: string, value: any) => void;
    barcode_filter?: BarcodeFilter;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}


// Component for meta form input with QR code scanner
export const TextQRcodeScanner: React.FC<TextQRcodeScannerProps> = ({
    item,
    object,
    setValue,
    barcode_filter,
    onFocus
}) => {
    const [open, setOpen] = useState(false);

    const handleScan = useCallback(
        (result: string) => {
            setValue(item.name, result);
            setOpen(false);
        },
        [item.name, setValue]
    );

    const onBarcodeButtonClick = useCallback(() => {
        if (barcode_filter) {
            setOpen(!open);
        } else {
            alert("Misconfiguration: Barcode filter not set!"); // eslint-disable-line no-alert
        }
    }, [barcode_filter, open]);

    return (
        <FormGroup>
            <Label>{item.label}</Label>
            {open && <QRCodeScan
                onScan={handleScan}
                barcode_filter={barcode_filter}
            />}
            <FormGroup>
                <Input
                    type={item.type || "text"}
                    name={item.name}
                    id={item.name}
                    value={object[item.name]}
                    onChange={(e) => setValue(item.name, e.target.value)}
                    invalid={!handlePatternValidation(item, object)}
                    onFocus={onFocus}
                />
                {!handlePatternValidation(item, object) && item.invalid_hint && (
                    <FormText>{item.invalid_hint}</FormText>
                )}
                <Button
                    color="primary"
                    className="text-nowrap"
                    style={{
                        position: "relative",
                        float: "right",
                        top: "-2.4em",
                    }}
                    onClick={onBarcodeButtonClick}
                >
                    <FontAwesomeIcon icon={faBarcodeRead} />
                </Button>
            </FormGroup>
        </FormGroup>
    );
};

export default QRCodeScan;
