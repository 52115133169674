/* eslint-disable @typescript-eslint/no-non-null-assertion */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PDFViewer, StyleSheet, View } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button, Container
} from "reactstrap";
import {
    getLayer,
    resetLayerState
} from "../../../../../actions/Layers/actions";
import { CHECK_LOCATION, LAYER_STAGE, VIEW } from "../../../../../actions/Layers/constants";
import { format2Frontend } from "../../../../../actions/Layers/util";
import useConfig, { useTenantFormOptions } from "../../../../../actions/Tenants/config/configHook";
import useAuthorization from "../../../../../utils/authorization";
import useAvosApi, { callAvosApi } from "../../../../../utils/useAvosApiHook";
import { useDefectsHook } from "../../../../Forms/useManagedOptionsHook";
import LoadingProgressBar from "../../../../Helper/LoadingProgressBar";
import { SendReportButton } from "../SendReportButton";
import { PDFImages } from "./PDFImages";
import { PDFCountDownText } from "./PDFIntakeReport";
import PDFLayerMeta from "./PDFLayerMeta";
import { PDFDocument, PDFPage } from "./PDFLayout";
import { PDFPallets } from "./PDFPallets";
import { PDFPalletsSummary } from "./PDFPalletsSummary";
import QCStatusButton from "./QCStatusButton";
import { prepareChecksForPDf, ReportType } from "./utils";

export default function PDFAdvanceRipeningReport() {
    const config = useConfig();
    const dispatch = useDispatch();
    // const user = useSelector<any>((state) => state.auth.user);
    const layer = useSelector<any>((state) => state.layers.current) as any;
    const tenantsIsLoading = useSelector<any>((state) => state.tenants.isLoading);
    const form_options = useTenantFormOptions();
    const [printChecks, setPrintChecks] = useState<any[]>([]);
    const [children, setChildren] = useState<any[]>([]);
    const params = useParams() as any;

    const layer_config = config.get_layer_config(layer);

    const navigate = useNavigate();
    useEffect(() => {
        dispatch(resetLayerState());
        dispatch(getLayer(params.layer_id) as any);
    }, [params.layer_id]);

    const all_defects = useDefectsHook(layer.fruit_type);

    const getWeekDayName = (date) => {
        if (!date) return "-";
        return new Date(date).toLocaleDateString("en-US", { weekday: "long" });
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!layer.id || (all_defects?.length || 0) === 0 || layer?.id !== params.layer_id) {
                return;
            }
            try {
                // Fetch children layers (pallets)
                const response = await callAvosApi(`/layers/${layer.id}/children`);
                const layers = response.data;

                const checks_response = await callAvosApi(`/layers/${layer.id}/checks`, {
                    params: {
                        with_fruit: true,
                        with_meta: true,
                        check_location: CHECK_LOCATION.ADVANCE_RIPENING,
                        view_strategy: VIEW.CHILDREN
                    }
                });
                const checks = checks_response.data;
                const formatted_checks = await prepareChecksForPDf(checks);

                // Sort pallets by latest check id and format for frontend usage
                const sorted_children = layers.results
                    .map((i) => ({ ...i, artChecks: formatted_checks.filter((c) => c.layer_id === i.id) }))
                    .map((i) => ({ ...i, latest_check: i.artChecks?.[i.artChecks.length - 1] }))
                    .filter((i) => i.latest_check && i.latest_location.stage === LAYER_STAGE.STORAGE)
                    .sort((a, b) => a.latest_check.test_id - b.latest_check.test_id)
                    .map((i) => format2Frontend(i));

                // Set children layers
                setChildren(sorted_children);

                // For every pallet, create a list of checks
                const layerResults = await Promise.all(sorted_children.map(async (childLayer) => {
                    // Format the checks for frontend
                    return childLayer.artChecks.map((check, index) => ({
                        ...childLayer,
                        label: `Day ${index + 1}`,
                        latest_check: check,
                        check_day: getWeekDayName(check.check_date), // Get the day name,
                        // avocados: check?.avocados,
                        all_images: check.all_images ? check.all_images.map((image) => ({ // for ART report we use current day for each image
                            ...image,
                            label: `Day ${index + 1}`
                        })) : [],
                        // table_data: getFruitTableData({
                        //     layer: childLayer,
                        //     check,
                        //     fruits: check.avocados,
                        //     config,
                        //     all_defects,
                        //     user
                        // })
                    }));
                }));

                // Update state with all formatted checks
                setPrintChecks(layerResults);

            } catch (error) {
                console.error("Error fetching data:", error); // eslint-disable-line no-console
            }
        };

        fetchData();
    }, [layer.id, all_defects?.length]);


    const isLoading = !layer_config || !printChecks || tenantsIsLoading || !children;

    const pdf_config = config.get_pdf_report(layer, "advance_ripening");


    const default_options = {
        children_table_fields: [],
        children_check_summary_meta_fields: [],
        pallet_meta_fields: [],
    };

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            justifyContent: "space-between",
            height: "70vh",
            marginTop: "20px"
        },
        cell1: {
            width: "42%", // Adjust based on your layout
            marginRight: "20px"
        },
        cell2: {
            width: "55%", // Adjust based on your layout
            // marginRight: "10px"
        },
    });

    const PrintReport = ({ onRender }) => {
        const { children_table_fields, logo_name, children_check_summary_meta_fields, pallet_meta_fields } = { ...default_options, ...pdf_config } as any;

        // * fields to show on first page of the purchase order
        const parent_layer_fields = layer_config.meta_display.map((i) => i.clone().set_value({
            config,
            layer,
            check: layer.latest_check, // * To be used in check tables and layer overview
            fruit: null, // * Fruit list is not available on overview screen
            children: null, // * children is only available for layer index your are viewing
            form_options // * used to translate form values to labels
        }));

        // * top left box of one art report per pallet
        const layer_fields = children.map((child) => {
            return pallet_meta_fields.map((i) => i.clone().set_value({
                config,
                layer: child,
                check: child.latest_check, // * To be used in check tables and layer overview
                fruit: null,
                children: null,
                form_options // * used to translate form values to labels
            }));
        });

        return (
            <>
                <PDFDocument onRender={onRender} title={`Advance ripening trial report ${layer?.label}`} >
                    <PDFPage logo={logo_name} header_title="Advance ripening trial report">
                        <View style={styles.row} wrap={false}>
                            <View style={styles.cell1}>
                                <PDFLayerMeta layer={layer} fields={parent_layer_fields} status={layer.advance_ripening_manual_flag ? layer.advance_ripening_manual_flag : layer.advance_ripening_overall_decision} />
                            </View>
                            <View style={styles.cell2}>
                                <PDFPalletsSummary
                                    fields={children_check_summary_meta_fields}
                                    printChildren={children}
                                    config={config}
                                    form_options={form_options} />
                            </View>
                        </View>
                    </PDFPage>
                    {layer_fields && printChecks.filter((array) => array.length > 0).map((child, index) => [
                        <PDFPallets
                            key={index}
                            fields={children_table_fields}
                            printChildren={child}
                            config={config}
                            form_options={form_options}
                            layer_fields={layer_fields[index]} />,
                        <PDFImages
                            key={index + 0.2}
                            images={child.flatMap((a) => a.all_images)}
                        />
                    ])}
                </PDFDocument>
            </>
        );
    };

    const [onSendReport] = useAvosApi("/notification/report-to-supplier", "POST", {} as any, {
        onSuccess: {
            message: "Report sending request received",
            showMessage: true
        }
    });
    const reportType = ReportType.ART;
    const [pdfBlob, setPdfBlob] = useState<Blob>();
    const onRender = async ({ blob }) => {
        if (!pdfBlob || pdfBlob.size !== blob.size) {
            setPdfBlob(blob);
        }
    };

    const auth = useAuthorization();
    const canSendReport = pdf_config?.show_send_email_button || auth.userBelongsToOneOfTeams(pdf_config?.teams_that_can_send);

    return <div className="px-3 py-4 bg-gray">
        <Container>
            <div className="d-flex justify-content-end align-items-center">
                <div className="pb-2 pb-sm-0 me-2 d-inline-flex d-md-block">
                    <h3 className="mb-0 ">{layer_config?.text} {layer.label} </h3>
                </div>
                <div className="pb-2 pb-sm-0 d-flex align-items-center ms-auto">
                    <QCStatusButton field="qc_status_advance_ripening" pdf_config={pdf_config} />
                    {canSendReport && onSendReport && <SendReportButton layerId={layer.id} blob={pdfBlob} reportType={reportType} onSendReport={onSendReport as any} statusField="qc_status_advance_ripening" />}
                    <Button className="btn-close my-1" size="lg" onClick={() => navigate(`/layer/${layer.id}`)} ></Button>
                </div>
            </div>
        </Container>
        <Container className="py-5">
            <div css={css`margin:auto;width: 420mm;`}>
                {isLoading ? (
                    <LoadingProgressBar text={PDFCountDownText.slice(0, 4)} />
                ) : (
                    <div>
                        {!pdfBlob && <LoadingProgressBar text={PDFCountDownText} startIndex={4} />}
                        <PDFViewer style={{ width: "100%", height: "1200px", opacity: pdfBlob ? 1 : 0 }}>
                            <PrintReport onRender={onRender} />
                        </PDFViewer>
                    </div>
                )}
            </div>
        </Container>
    </div>;
}
